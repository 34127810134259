<template>
  <div>
    <div class="new-top">
      <i class="el-icon-s-custom"></i>
      <span class="pub">我发布的职位</span>
      <div class="circle">{{ this.total - 1 }}</div>
      <el-tabs
        :tab-position="tabPosition"
        style="min-width: 120px"
        @tab-click="changeJob"
      >
        <el-tab-pane
          class="line_clamp1"
          :label="item.jobName"
          v-for="(item, index) in jobNameList"
          :key="index"
        >
          <span slot="label" class="line_clamp1">
            <span :title="item.jobName">{{ item.jobName }}</span>
          </span>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "findPersonnel",
  data() {
    return {
      tabPosition: "right",
      // activeName: "first",
      total: 0,
      jobNameList: [],
    };
  },
  methods: {
    changeJob(tab, event) {
      this.$emit("sendJobId", this.jobNameList[tab.index].id);
    },
    async getJobName() {
      let that = this;
      let res = await that.$axios.get(
        "/api/app-jycy-job/getJobNameListByCompanyId"
      );
      let none = {
        id: "",
        jobId: "",
        jobName: "全部",
      };
      this.jobNameList = res.data.data;
      this.jobNameList.unshift(none);
      this.total = that.jobNameList.length;
      if (this.jobNameList.length > 0) {
        this.$emit("sendJobId", this.jobNameList[0].id);
      } else {
        this.$emit("sendJobId", "-1");
      }
    },
  },
  mounted() {
    this.getJobName();
  },
};
</script>

<style lang="less" scoped>
.new-top {
  width: 200px;
  // height: 169px;
  border: 1px solid #e1e1e1;
  margin-bottom: 10px;
  position: relative;
  
  i {
    padding: 0px 4px 0px 20px;
    font-size: 16px;
    color: #666666;
    display: inline-block;
  }

  .pub {
    display: inline-block;
    padding: 10px 0px 8px 8px;
    //  text-align: center;
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: rgb(234, 65, 58);
    font-weight: 600;
    // background:rgb(234, 65, 58);
    top: 8px;
    left: 135px;
    text-align: center;
    padding: 5px;
  }

  .el-tabs {
    margin-left: 40px;
    margin-bottom: 10px;
    overflow: inherit;
    overflow-x: scroll;
    span {
      width: 80px;
      display: inline-block;
    }
  }
}
</style>