<template>
  <div class="recruiting-wrapper">
    <div class="search">
      <RecruitSearch @selects="selects"></RecruitSearch>
    </div>
    <div class="lists">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 最新人才 -->
        <el-tab-pane
          label="最新人才"
          name="first"
          class="loads"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <div class="content">
            <div class="aside left">
              <FindPersonnel @sendJobId="sendJobId" v-if="activeName == 'first'"></FindPersonnel>
              <div class="ad relative" v-for="(n,i) in advList" :key="i">
                <a :href="n.picUrl" :title="n.adName" class="imgAd" target="_blank">
                  <img :src="n.picSrc" :alt="n.adName" :title="n.adName" class="err_image" />
                </a>
              </div>
            </div>
            <div class="aside main" v-if="status.isHide == 0">
              <div class="forin" v-for="item in getResumesList" :key="item.memberId">
                <Info :item="item"></Info>
              </div>
            </div>
            <div v-else>
              <status :type="status.type" :des="status.des"></status>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              class="work_pagination"
              :current-page.sync="resumes.pageNum"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="resumes.pageSize"
              layout="prev, pager, next, jumper"
              :prev-text="pagination.pre"
              :next-text="pagination.next"
              :total="total"
            ></el-pagination>
          </div>
        </el-tab-pane>

        <!-- 机构推荐 -->
        <el-tab-pane
          label="机构推荐"
          name="second"
          @tab-click="handleClick"
          class="loads"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <div class="content">
            <div class="aside left">
              <FindPersonnel @sendJobId="sendJobId" v-if="activeName == 'second'"></FindPersonnel>
              <div class="ad relative" v-for="(n,i) in advList" :key="i">
                <a :href="n.picUrl" :title="n.adName" class="imgAd" target="_blank">
                  <img :src="n.picSrc" :alt="n.adName" :title="n.adName" class="err_image" />
                </a>
              </div>
            </div>
            <div class="aside main" v-if="status.isHide == 0">
              <div class="forin" v-for="item in getOrganList" :key="item.memberId">
                <Info :item="item"></Info>
              </div>
            </div>
            <div v-else>
              <status :type="status.type" :des="status.des"></status>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              class="work_pagination"
              :current-page.sync="Organ.pageNum"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="Organ.pageSize"
              layout="prev, pager, next, jumper"
              :prev-text="pagination.pre"
              :next-text="pagination.next"
              :total="total"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <!-- 谁看过我 -->
        <el-tab-pane
          label="谁看过我"
          name="third"
          @tab-click="handleClick"
          class="loads"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <div class="content">
            <div class="aside left">
              <FindPersonnel @sendJobId="sendJobId" v-if="activeName == 'third'"></FindPersonnel>
              <div class="ad relative" v-for="(n,i) in advList" :key="i">
                <a :href="n.picUrl" :title="n.adName" class="imgAd" target="_blank">
                  <img :src="n.picSrc" :alt="n.adName" :title="n.adName" class="err_image" />
                </a>
              </div>
            </div>
            <div class="aside main" v-if="status.isHide == 0">
              <div class="forin" v-for="item in getLookMeList" :key="item.memberId">
                <Info :item="item"></Info>
              </div>
            </div>
            <div v-else>
              <status :type="status.type" :des="status.des"></status>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              class="work_pagination"
              :current-page.sync="LookMe.pageNum"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="LookMe.pageSize"
              layout="prev, pager, next, jumper"
              :prev-text="pagination.pre"
              :next-text="pagination.next"
              :total="total"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import Info from "components/enterprise/Info";
import RecruitSearch from "components/search/RecruitSearch";
import FindPersonnel from "components/enterprise/recruit/FindPersonnel";
export default {
  name: "recruiting",
  components: {
    Info,
    RecruitSearch,
    FindPersonnel,
  },
  data() {
    return {
      tabPosition: "right",
      activeName: "first",
      recommend: "最新人才",
      advList: [],
      pagination: {
        pre: "上一页",
        next: "下一页",
      },
      status: {
        isHide: 0,
        type: "",
        des: "暂无人才数据",
      },
      loading: false,
      total: 0,
      show: false,
      getResumesList: [],
      getOrganList: [],
      getLookMeList: [],
      resumes: {
        pageNum: 1,
        pageSize: 10,
        jobId: "",
      },
      Organ: {
        pageNum: 1,
        pageSize: 10,
        beginStatus: 9,
        jobId: "",
      },
      LookMe: {
        pageNum: 1,
        pageSize: 10,
        beginStatus: 8,
        jobId: "",
      },
      tabIndx: 0,
      disabledInfoI: {},
    };
  },


  methods: {
    sendJobId(jobId) {
      this.resumes.jobId = jobId;
      this.Organ.jobId = jobId;
      this.LookMe.jobId = jobId;
      let params = {
        pageNum: 1,
        pageSize: 10,
      };
      this.selects(params, 1);
    },
    // 切换tab
    handleClick(tab, event) {
      this.tabIndx = tab.index;
      this.selects(this.disabledInfoI);
    },
    //广告位
    adv() {
      this.$api
        .adApi({ classId: "4028815473e6bfe90173e6c407d10004" })
        .then((res) => {
          if (res.data.success) {
            this.advList = res.data.data;
          }
        });
    },
    // 搜索 子组件传递的事件
    selects(disabledInfo, val) {
      let index = this.tabIndx;
      this.disabledInfoI = disabledInfo;
      if (index == 0) {
        this.resumes = {
          ...this.resumes,
          ...disabledInfo,
        };
        if (val) {
          this.resumes.pageNum = val;
        }
        this.getResumes();
      } else if (index == 1) {
        this.Organ = {
          ...this.Organ,
          ...disabledInfo,
        };
        if (val) {
          this.Organ.pageNum = val;
        }
        //  beginStatus: 9
        this.getOrgan();
      } else {
        this.LookMe = {
          ...this.LookMe,
          ...disabledInfo,
        };
        if (val) {
          this.LookMe.pageNum = val;
        }
        this.getLookMe();
      }
    },

    // 找人才数据请求
    async getResumes() {
      let that = this;
      this.loading = true;
      let res = await that.$api.getList(that.resumes);
      let data = this.$decrypt(res.data);
      if (data.success) {
        this.getResumesList = data.data.records;
        this.total = data.data.total;
        this.loading = false;
        if (this.getResumesList.length <= 0) {
          this.status.isHide = 1;
          this.status.type = "post";
          this.status.des = "暂无人才数据!";
        } else {
          this.status.isHide = 0;
        }
      } else {
        this.$message.error(data.msg);
        this.loading = false;
      }
    },
    async getOrgan() {
      let that = this;
      this.loading = true;
      let res = await that.$api.getList(that.Organ);
      let data = this.$decrypt(res.data);
      console.log(data)
      if (data.success) {
        this.getOrganList = data.data.records;
        this.total = data.data.total;
        this.loading = false;
        if (this.getOrganList.length <= 0) {
          this.status.isHide = 1;
          this.status.type = "post";
          this.status.des = "暂无人才数据!";
        } else {
          this.status.isHide = 0;
        }
      } else {
        this.$message.error(data.msg);
        this.loading = false;
      }
    },
    // 谁看过我
    async getLookMe() {
      let that = this;
      this.loading = true;
      let res = await that.$api.getList(that.LookMe);
      let data = this.$decrypt(res.data);
      if (data.success) {
        this.getLookMeList = data.data.records;
        console.log(this.getLookMeList)
        this.total = data.data.total;
        this.loading = false;
        if (this.getLookMeList.length <= 0) {
          this.status.isHide = 1;
          this.status.type = "post";
          this.status.des = "暂无人才数据!";
        } else {
          this.status.isHide = 0;
        }
      } else {
        this.$message.error(data.msg);
        this.loading = false;
      }
    },
    // 分页
    handleCurrentChange(val) {
      // 直接调用传递的参数
      this.selects(this.disabledInfoI, val);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.selects(this.disabledInfoI);
    },
    // 改变路由
    changeRouter(ids) {
      if (ids) {
        this.activeName = ids;
      }
    },
  },
  mounted() {
    if (this.$route.query.ids){
      this.activeName = this.$route.query.ids;
      if (this.$route.query.ids == 'first'){
        this.tabIndx = 0;
      }else if (this.$route.query.ids == 'second'){
        this.tabIndx = 1;
      }else{
        this.tabIndx = 2;
      }
    }
  },
  created() {
    this.changeRouter(this.$route.query.ids);
    this.adv();
  },
};
</script>

<style lang="less" scoped>
@import "~assets/css/enterprise/recruitting.less";
/deep/ .el-loading-spinner {
  top: 13%;
}
</style>