<template>
  <div class="recruitSearch">
    <div class="search cursorP">
      <el-input
        placeholder="可根据求职意向、学校搜索您想要的人才、也可点击高级搜索进行筛选"
        v-model="input"
        clearable
        class="int_search"
      >
        <template slot="append">
          <p @click="searchs">搜索</p>
        </template>
      </el-input>
      <div class="highSearch cursorP" @click="show = !show">
        <i class="icon"></i>
        <p>高级搜索</p>
      </div>
    </div>

    <div v-show="show">
      <div class="highLevel">
        <div class="texts">
          <p>选择地区：</p>
          <ThreeLink
            :show_level="false"
            :codeType="'provinceid'"
            :codeLevel="'3'"
            :changeonselect="true"
            size="mini"
            class="w120"
            @setMValue="
              setScasValue($event, disabledInfo, ['provinceid', 'cityid'])
            "
            :defaultValue="[disabledInfo.provinceid, disabledInfo.cityid]"
          ></ThreeLink>
        </div>
        <div class="texts">
          <p>残疾类别：</p>
          <MulSelect
            :code="'disabled_type'"
            size="mini"
            class="w120"
            @setMValue="setSelectValue($event, 'disabledType')"
            :defaultValue="disabledInfo.disabledType"
            >></MulSelect
          >
        </div>
        <div class="texts">
          <p>期望行业：</p>
          <ThreeLink
            :codeType="'hy1'"
            :show_level="false"
            :codeLevel="'2'"
            size="mini"
            @setMValue="setScasValue($event, disabledInfo, ['hyTop', 'hy'])"
            :defaultValue="[disabledInfo.hyTop, disabledInfo.hy]"
            class="w120"
          ></ThreeLink>
        </div>
        <div class="texts">
          <p>期望职位：</p>

          <ThreeLink
            :show_level="false"
            :codeType="'job1'"
            :codeLevel="'2'"
            size="mini"
            class="w120"
            @setMValue="
              setjobValue($event, disabledInfo, ['jobTop', 'jobNext'])
            "
            :defaultValue="[disabledInfo.jobTop, disabledInfo.jobNext]"
          ></ThreeLink>
        </div>
        <div class="texts">
          <p>期望工资：</p>
          <MulSelect
            :code="'user_salary'"
            size="mini"
            class="w120"
            @setMValue="setSelectValue($event, 'salary')"
            :defaultValue="disabledInfo.salary"
          ></MulSelect>
        </div>
        <div class="texts">
          <p>工作经验：</p>
          <MulSelect
            :code="'user_word'"
            size="mini"
            class="w120"
            @setMValue="setSelectValue($event, 'exp')"
            :defaultValue="disabledInfo.exp"
          ></MulSelect>
        </div>
        <div class="texts">
          <p>学历：</p>
          <MulSelect
            :code="'user_edu'"
            size="mini"
            class="w120"
            @setMValue="setSelectValue($event, 'edu')"
            :defaultValue="disabledInfo.edu"
          ></MulSelect>
        </div>
        <div class="texts">
          <p>性别：</p>
          <MulSelect
            :code="'user_sex'"
            size="mini"
            class="w120"
            @setMValue="setSelectValue($event, 'sex')"
            :defaultValue="disabledInfo.sex"
          ></MulSelect>
        </div>
      </div>
      <!-- <div class="texts textsage flexa">
        <p>招聘年龄：</p>
        <div class="block">
          <el-slider v-model="disabledInfo.age" range :marks="marks">
          </el-slider>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import MulSelect from "../../components/selectComponents/MulSelect";
import ThreeLink from "../../components/selectComponents/ThreeLink";
import SelectCategory from "../../components/selectComponents/selectCategory";
export default {
  components: { SelectCategory, MulSelect, ThreeLink },
  data() {
    return {
      textList: [
        "选择地区",
        "期望行业",
        "期望职业",
        "残疾类别",
        "期望工资",
        "学历",
        "性别",
        "年龄",
      ],

      marks: {
        0: "0",
        20: "20",
        35: "35",
        50: {
          style: {
            color: "#00924B",
          },
          label: this.$createElement("strong", "50"),
        },
      },

      options: [],
      show: false,
      input: "",
      search: {},
      disabledInfo: {
        age: [20, 35],
        cityid: "", //市
        disabledDetail: "", //残疾描述disabled_class
        disabledLevel: "", //残疾等级disabled_class
        disabledType: "", //残疾类别disabled_class
        edu: "", //学历user_edu
        exp: "", //工作经验
        hy: "", //行业第二级
        hyTop: "", //行业第一级
        input: "", //输入框内容
        jobNext: "", //职位第二级
        jobPost: "", //职位第三级
        jobTop: "", //职位第一级
        provinceid: "", //省
        salary: "", //工资user_salary
        sex: "", //性别
        threeCityid: "", //区
      },
    };
  },
  computed: {
    //设置计算属性
    fSearch() {
      if (this.disabledInfo) {
        return this.list.filter((value) => {
          //过滤数组元素
          return value.includes(this.disabledInfo); //如果包含字符返回true
        });
      }
    },
  },
  watch: {
    disabledInfo: {
      handler(newName, oldName) {
        if (oldName) {
          this.$emit("selects", newName);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    //省市区三级联动
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    // 职位筛选
    setjobValue(event, form, keys) {
      console.log(event);
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    searchs() {
      this.disabledInfo.input = this.input;
      this.$emit("selects", this.disabledInfo);
    },
    // 下拉选择
    setSelectValue(val, key) {
      this.disabledInfo[key] = val;
    },
  },
};
</script>

<style lang="less" scoped>
.recruitSearch {
  background: #ffffff;
  margin-bottom: 15px;
  padding: 21px 195px;
  padding-bottom: 18px;
  box-sizing: border-box;

  /deep/ .el-input-group__append,
  .el-input-group__prepend {
    background-color: #00924c;
    color: white;
    border: 1px solid #00924c;
    padding: 0;
    height: 40px;
      transform: translateY(-1px);
    p{
      width: 110px;
      height: 39px;
      line-height: 39px;
      text-align: center;
    }
  }
}

// .el-input-group__append .search_s {
//   width: 60px;
//   height: 20px;
//   background: blue;

// }
.search {
  display: flex;
  .int_search {
    width: 700px;
    height: 40px;
  }
  .highSearch {
    margin-bottom: 15px;
    .icon {
      width: 16px;
      height: 15px;
      background: url("../../static/resumes/icon.png") center center no-repeat;
      background-size: 100% 100%;
      margin-right: 3px;
    }

    width: 102px;
    height: 43px;
    background: url("../../static/resumes/highSearch.png") center center
      no-repeat;
    background-size: 100% 100%;
    margin-left: 10px;
    p {
      line-height: 34px;
      color: #666666;
      height: 38px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.highLevel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .texts {
    display: flex;
    width: 25%;
    // justify-content: space-between;
    margin-bottom: 15px;
    // margin-right: 10px;
    align-items: center;
    p {
      text-align: right;
      width: 70px;
    }
  }
  .textsage {
    width: 100% !important;
    .el-slider {
      width: 100%;
    }
  }
}
/deep/ .el-slider__runway {
  width: 600px !important;
}
/deep/ .el-slider__marks-text {
  top: 0px;
}
</style>
